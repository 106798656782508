.mains {
  width: 100%;
  height: 100%;
}


.head {
  width: 100%;
  background-color: #b4874e;
  height: 40px;
  display: flex;
}

.head2 {
  width: 32%;
  height: 100%;
  border: 1px solid white;
  margin-left: 15px;
  border-radius: 16px;
}

.head2 p {
  color: white;
  margin-left: 20px;
  padding-top: 04px;
}

.navigate {
  position: sticky;
  top: 0;
  background-color: white;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: black;
  font-size: 20px;
  padding-top: 15px;
  margin-left: 30px;
  height: 70px;
}


.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: red;

}

.logo {
  margin-top: -35px;
  margin-left: -70px;
}

.logo h1 {
  font-size: 25px;
  margin-left: 90px;
  margin-top: -60px;
}




.header {
  height: 450px;
  width: 100%;
}

.header img {
  width: 100%;
  height: 450px;
  display: block;
}

.header h1 {
  text-align: center;
  margin-top: -140px;
  color: white;
  text-shadow: 0 0 5px black;
}


.section {
  width: 100%;
  height: 450px;
  box-shadow: 2px 1px 5px 2px black;
  margin-top: 100px;
  display: flex;
}

.section2 {
  width: 50%;
  height: 450px;

}

.section2 h1 {
  margin-left: 40px;
  margin-top: 20px;
  text-shadow: 0 0 4px black;
  color: black;

}

.section2 h2 {
  margin-left: 40px;
  margin-top: 20px;
}

.section2 p {
  margin-left: 40px;
  margin-top: 20px;
  font-size: 18px;
  font-family: Tahoma, Verdana, sans-serif;
}

.section2 img {
  width: 100%;
  height: 100%;
}

.serve {

  width: 300px;
  margin-left: 40%;
  border-radius: 16px;
  background-color: black;
  color: white;
}



.services {
  width: 100%;
  height: 550px;
  display: flex;
  margin-top: 40px;
}


.services2 {
  width: 29%;
  height: 550px;
  margin-left: 40px;
  background-color: #b4874e;
}

.services2 img {
  width: 100%;
  height: 450px;
}

.services2 h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 25px;
  font-weight: bold;
}


.media {
  width: 100%;
  height: 400px;
  display: flex;
  margin-top: 100px;
  background-color: black;
}

.media2 {
  width: 50%;
  height: 400px;
}

.media2 img {
  width: 450px;
  height: 380px;
  margin-left: 60px;
  padding-top: 14px;
}

.media2 p {
  color: white;
  margin-top: 20px;
  font-size: 19px;
}



.footer {
  width: 100%;
  box-shadow: 2px 3px 5px 2px black;
  height: 400px;
  display: flex;
  margin-top: 100px;
  background-color: #FAF9F6;
}

.footer2 {
  width: 25%;
  height: 400px;

}

.footer2 h1 {
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}

.footer2 p {
  margin-left: 10px;
  margin-top: 20px;
  color: black;
}

.footer2 {
  font-size: 17px;
}

.contact {
  display: flex;
  justify-content: space-between;
}

.contact2 {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.appointment {
  width: 700px;
  margin-left: 350px;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.fixed-size-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  /* This property ensures that the entire image is covered within the fixed size */
}



@media (max-width: 650px) {

  .mains {
    width: 100%;
    height: 100%;
  }


  .head {
    flex-direction: column;
    /* Stack items vertically */
    height: auto;
    /* Adjust height as needed */
    display: none;
  }

  .head2 {
    width: 100%;
    /* Full width for smaller screens */
    margin-left: 0;
    /* Remove left margin */
    margin-top: 10px;
    /* Add some top margin */
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    margin-left: 0;
    /* Remove left margin for navigation links */
    text-align: center;
    /* Center text for navigation links */
    height: 70px;
  }

  .logo {
    margin-top: -35px;
    margin-left: -190px;
  }

  .logo h1 {
    font-size: 20px;
    margin-left: 250px;
    margin-top: -60px;
  }

  .header {
    height: 250px;
    width: 100%;
  }

  .header img {
    width: 100%;
    height: 250px;
    display: block;
  }

  .header h1 {
    text-align: center;
    margin-top: -170px;
    color: white;
    text-shadow: 0 0 5px black;
  }


  .section {
    width: 100%;
    height: 100%;
    box-shadow: 2px 1px 5px 2px black;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .section2 {
    width: 100%;
    height: 100%;

  }

  .section2 h1 {
    margin-left: 40px;
    margin-top: 20px;
    text-shadow: 0 0 4px black;
    color: red;

  }

  .section2 h2 {
    margin-left: 40px;
    margin-top: 20px;
  }

  .section2 p {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 15px;
    font-family: Tahoma, Verdana, sans-serif;
  }

  .section2 img {
    width: 100%;
    height: 100%;
  }

  .serve {
    margin-left: 10%;
    /* Adjust margin for the serve div */
  }

  .services {
    flex-direction: column;
    /* Stack services vertically */
    height: auto;
    /* Adjust height as needed */
  }

  .services2 {
    width: 100%;
    /* Full width for smaller screens */
    margin-left: 0;
    /* Remove left margin */
    margin-top: 20px;
    /* Add some top margin */
  }


  .media {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    background-color: black;
  }

  .media2 {
    width: 100%;
    height: 100%;
  }

  .media2 img {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    padding-top: 14px;
  }



  .media2 p {
    color: white;
    margin-top: 20px;
    font-size: 19px;
    margin-left: 10px;
  }


  .footer {
    width: 100%;
    border: none;
    height: 100%;
    display: flex;
    margin-top: 50px;
    flex-direction: column;
  }

  .footer2 {
    width: 100%;
    height: 100%;
    margin-top: 20px;
  }

  .footer2 h1 {
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
  }

  .footer2 p {
    margin-left: 10px;
    margin-top: 20px;
    color: black;
  }

  .footer2 {
    font-size: 17px;
  }

  .contact {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .contact2 {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .appointment {
    width: 100%;
    margin-left: 0px;
  }


  .image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
  }

  .fixed-size-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    /* This property ensures that the entire image is covered within the fixed size */
  }

}



@media (min-width: 651px) and (max-width: 1250px) {
  .mains {
    width: 1250px;
    height: 100%;
  }


  .head {
    width: 100%;
    background-color: #b4874e;
    height: 40px;
    display: flex;
  }

  .head2 {
    width: 32%;
    height: 100%;
    border: 1px solid white;
    margin-left: 15px;
    border-radius: 16px;
  }

  .head2 p {
    color: white;
    margin-left: 20px;
    padding-top: 04px;
  }

  .navigate {
    position: sticky;
    top: 0;
    background-color: white;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 20px;
    padding-top: 15px;
    margin-left: 30px;
    height: 70px;

  }


  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: red;

  }

  .logo {
    margin-top: -35px;
    margin-left: -70px;
  }

  .logo h1 {
    font-size: 25px;
    margin-left: 90px;
    margin-top: -60px;
  }




  .header {
    height: 450px;
    width: 100%;
  }

  .header img {
    width: 100%;
    height: 450px;
    display: block;
  }

  .header h1 {
    text-align: center;
    margin-top: -140px;
    color: white;
    text-shadow: 0 0 5px black;
  }


  .section {
    width: 100%;
    height: 450px;
    box-shadow: 2px 1px 5px 2px black;
    margin-top: 100px;
    display: flex;
  }

  .section2 {
    width: 50%;
    height: 450px;

  }

  .section2 h1 {
    margin-left: 40px;
    margin-top: 20px;
    text-shadow: 0 0 4px black;
    color: red;

  }

  .section2 h2 {
    margin-left: 40px;
    margin-top: 20px;
  }

  .section2 p {
    margin-left: 40px;
    margin-top: 20px;
    font-size: 18px;
    font-family: Tahoma, Verdana, sans-serif;
  }

  .section2 img {
    width: 100%;
    height: 100%;
  }

  .serve {

    width: 300px;
    margin-left: 40%;
    border-radius: 16px;
    background-color: black;
    color: white;
  }



  .services {
    width: 100%;
    height: 550px;
    display: flex;
    margin-top: 40px;
  }


  .services2 {
    width: 29%;
    height: 550px;
    margin-left: 40px;
    background-color: #b4874e;
  }

  .services2 img {
    width: 100%;
    height: 450px;
  }

  .services2 h1 {
    text-align: center;
    font-size: 30px;
    margin-top: 25px;
    font-weight: bold;
  }


  .media {
    width: 100%;
    height: 400px;
    display: flex;
    margin-top: 100px;
    background-color: black;
  }

  .media2 {
    width: 50%;
    height: 400px;
  }

  .media2 img {
    width: 450px;
    height: 380px;
    margin-left: 60px;
    padding-top: 14px;
  }

  .media2 p {
    color: white;
    margin-top: 20px;
    font-size: 19px;
  }



  .footer {
    width: 100%;
    border: 1px solid black;
    height: 400px;
    display: flex;
    margin-top: 100px;
  }

  .footer2 {
    width: 25%;
    height: 400px;

  }

  .footer2 h1 {
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
  }

  .footer2 p {
    margin-left: 10px;
    margin-top: 20px;
    color: black;
  }

  .footer2 {
    font-size: 17px;
  }


  
.contact {
  display: flex;
  justify-content: space-between;
}

.contact2 {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.appointment {
  width: 700px;
  margin-left: 350px;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.fixed-size-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  /* This property ensures that the entire image is covered within the fixed size */
}


}